import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Entity } from '../../services/entity.service';
import { setActiveTabStatus, setInactivityStatus, setInactivityTimer } from './utils.actions';
import { map, tap } from '@proman/rxjs-common';
@Injectable()
export class UtilsEffects {
    inactivityEnaled: boolean;

    handleInactivityTimer$ = createEffect(() => this.actions$
        .pipe(
            ofType(setInactivityTimer),
            map((action) => action.payload),
            tap((timer) => {

              if (timer) {
                  this.inactivityEnaled = true;
                  this.handleTimeout(timer);
              } else {
                  this.inactivityEnaled = false;
                  this.store.dispatch(setInactivityStatus({ payload: false }));

              }

            })
        ),
        { dispatch: false }
    )

    constructor(
        private Entity: Entity,
        private store: Store,
        private actions$: Actions,
    ) {
        window.onfocus = () => {
            this.store.dispatch(setActiveTabStatus({ payload: true }));
        };

        window.onblur = () => {
            this.store.dispatch(setActiveTabStatus({ payload: false }));
        };

    }

    handleTimeout(timer: number) {
        const idleLogout = () => {
            let t: ReturnType<typeof setTimeout>;

            const timerTrigger = () => {
                if (this.inactivityEnaled) {
                    this.store.dispatch(setInactivityStatus({ payload: true }));
                }
            };

            const resetTimer = () => {
               if (this.inactivityEnaled) {
                   this.store.dispatch(setInactivityStatus({ payload: false }));

                   clearTimeout(t);
                   t = setTimeout(timerTrigger, timer);  // time is in milliseconds
               }
            };

            window.onload = resetTimer;
            window.onmousemove = resetTimer;
            window.onmousedown = resetTimer;  // catches touchscreen presses as well
            window.ontouchstart = resetTimer; // catches touchscreen swipes as well
            window.ontouchmove = resetTimer;  // required by some devices
            window.onclick = resetTimer;      // catches touchpad clicks as well
            window.onkeydown = resetTimer;
            window.addEventListener('scroll', resetTimer, true); // improved; see comments

        }

        idleLogout();
    }

}
